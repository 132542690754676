.card {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.card-text {
  padding: 15px;
}

.card-header {
  font-size: large;
  font-weight: bold;
}

.card-subheader {
  color: #8c8c8c;
}