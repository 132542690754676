@import "events";

/*---font---*/
@font-face {
  font-family: "Work Sans";
  src: url("../../assets/fonts/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "Work Sans";
  src: url("../../assets/fonts/WorkSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Work Sans";
  src: url("../../assets/fonts/WorkSans-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "Work Sans";
  src: url("../../assets/fonts/WorkSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

/*---variables---*/
$font-stack: "Work Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

/*---HPI Corporate Design colors---*/
$hpi-yellow: rgb(246,168,0);
$hpi-orange: rgb(221,97,8);
$hpi-red: rgb(177,6,58);
$hpi-darkgrey: rgb(90,96,101);
$hpi-blue: rgb(0,122,158);
$hpi-lightblue: #D7E7ED;

$primary-color: $hpi-blue;
$secondary-color: $hpi-red;

$text-light: #586368;
$text-dark: #34434B;

$background-light: lighten($text-light, 60);

/*-----------layout variables-------*/
$container-width: 1200px;

/*-----------headlines------------*/
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: $text-dark;
  width: 100%;
}

h1 {
  font-size: 2.4rem;
  border-bottom: 3px solid $hpi-red;
  line-height: 1.2;
}
h1:first-of-type {
  margin-top: 0px;
}

h2 {
  font-size: 1.8rem;
	color: $hpi-orange;
}

.banner {
  font-size: 2.2rem;
  font-weight: bold;
  border-bottom: none;
  grid-column: content;
  grid-row: line1;
  text-align: right;
  color: $text-dark;
  align-self: center;
}

.banner, nav {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

/*-----------layout------------*/
html { 
  scroll-behavior: smooth; 
  font: normal 400 1rem/1.6 $font-stack;
}

.container {
    max-width: $container-width;
    display: grid;
    grid-template-columns: [sidebar] 250px [content] auto;
    grid-template-rows: [line1] 150px [line2] auto [line3] 240px [line4];
    grid-gap: 10px 10px;
    margin: 0 auto; // center container in window

  > * { 
    padding: 12px;
  }
}

body {
  color: $text-light;
} 

header {
  grid-column: sidebar;
  grid-row: line1;
  text-align: center;

  img {
    width: 220px;
    height: auto;
  }
}

nav {
	max-width: 800px;
	font-size: 1.3rem;
	display: block;
	grid-column: content;
	grid-row: line1;
	text-align: center;
  align-self: flex-end;
  justify-self: end;

  li {
    list-style-type: none; 
    margin-right: 1em;
    a {
      color: $text-dark;
    }

    a:hover {
      color: $hpi-blue;
    }
  }

  li:last-of-type {
    margin-right: 0;
  }

  ul {
    display: flex; 
    flex-flow: row nowrap;
    justify-content: flex-end;
    text-align: right;
    padding-left: 0;
    margin-bottom: 0;
  }
}

nav label, #menu {
  display: none;
}

#sidebar-wrapper {
	grid-column: sidebar;
	grid-row: line2;
  padding: 0 !important;
}

#sidebar-sticky {
	position: sticky;
	position: -webkit-sticky;
	top: 100px;
  background: $background-light;
  padding: 0.5em 1em;
}

#sidebar-subnav {
	font-size: 18px;
	flex-flow: row nowrap;
	padding-left: 0px;

	li {
		list-style-type: none;
		padding-bottom: 0.8em;
    line-height: 1.2;

    a:hover {
      text-decoration: underline;
    }
	}
}

section {
	max-width: $container-width;
	grid-column: content;
	grid-row: line2;
}

#footer-left {
	grid-column: sidebar;
	grid-row: line3;
	font-size: 14px;
  background: $background-light;
}

#footer-right {
	grid-column: content;
	grid-row: line3;
	font-size: 14px;
  border-top: solid 3px $hpi-red;
}

// Flexbox layout for smaller screens
@media screen and (max-width: 820px) {
  .container {
		display: flex;
		flex-flow: column wrap;
    text-align: left;
	}

  header {
    order: 1;
    text-align: left;
    position: absolute;
  }
  .banner {
    text-align: right;
    align-self: flex-end;
    max-width: 230px;
    font-size: 1.75rem;
    line-height: 1;
    padding-top: 15px;
  }
  nav {
    order: 2;
    align-self: center;
    padding-top: 0px !important;
    display: block;
  }

  .sidebar {
    display: none;
  }
  section {
    order: 3;
  }
  #footer-right {
    order: 4;
  }
  #footer-left {
    order: 5;
  }

  .logo {
    height: 100px;
    width: auto;
  }
}

// Mobile: show hamburger menu for main navigation
@media screen and (max-width: 580px) {
  nav {
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    flex-flow: column nowrap;
    top: 12px; // page top margin
    align-self: flex-end;
    z-index: 1;
  }

  nav label {
    display: block;
    align-self: flex-end;
    color: $text-dark;
    font-size: 30px;
    width: 45px;
    height: 40px;
    padding-bottom: 5px;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.18);
    opacity: 0.9;
    user-select: none;
  }
  nav label:hover {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px); // shift up animation
    cursor: pointer;
    color: darken($hpi-blue, 10);
  }

  nav ul li {
    display: block;
    font-size: 1.2em;
  }

  nav ul {
    display: none;
  }

  // Displays nav when pressed
  nav input:checked ~ ul {
    display: block;
    text-align: left;
    background: $background-light;
    border: 3px solid $text-light;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.18);
    border-radius: 6px;
    user-select: none;
    padding-left: 1em;
    position: fixed;
    top: 40px;
    right: 60px;
    z-index: 5;
  }
}

/*-----------general------------*/
img { max-width: 100%; }

a {
	text-decoration: none;
	color: $hpi-blue;
}

a:hover {
  text-decoration: none;
  color: darken($hpi-blue, 40);
}

/*-----------concrete sections------------*/
section.people,
section.person {
  .person-photo-large {
    width: 200px;
    height: auto;
  }
  
  .person-wrapper {
    display: flex;
    flex-flow: row wrap;
  }
  
  .person-short {
    width: 380px;
    margin: 0.5em 0;
  }
  
  .person-photo-small {
    width: 100px;
    height: 130px;
    object-fit: cover;
  }
  
  .person-info {
    width: 250px;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    line-height: 0.9;
  
    p {
      font-size: 14px;
    }
  }
  .person-info > * {
    word-wrap: break-word;
  }
  
  .person-info-long {
    position: relative;
    line-height: 0.5;
    display: inline-block;
    margin: 0 1em;
  }

  span.now-at {
    font-size: 14px;
  }
}

section.tools {
  .tools-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div.tool {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: $hpi-lightblue;
      color: $text-dark;
      flex-grow: 1;
      margin: 1em 1em 1em 0;
      padding: 0.5em;

      p {
        max-width: 250px;
      }
    }

    div.tool:hover {
      background-color: lighten($hpi-lightblue, 10%);
    }
  }
}

section.projects {
  div.logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    border-left: 10px solid $hpi-lightblue;
    margin-top: 1rem;
  
    img {
      max-height: 100px;
      margin: 10px;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }

  h3.subtitle,
  p.timeframe,
  p.persons {
    margin: 0.1rem 0;
  }

  h4 {
    font-size: 1.1rem;
  }
}
