@import "main";

// Remove sidebar from landing page
.sidebar {
  display: none;
}

// Make content section span entire webpage width
section {
  grid-column: sidebar / span 2 !important; 
}

.landing-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-info {
  margin-bottom: 2rem; // Space between text and image
}

.team-photo {
  max-width: 600px; // Restrict image size
  margin-bottom: 1.5rem; // Space before news section
}

.team-photo img {
  width: 100%; // Ensure responsiveness
  height: auto;
  border-radius: 8px; // Optional styling for a sleek look
}

.landing-news {
  text-align: left;
  padding-top: 2rem;
  border-top: 1px solid #ddd; // Separates it visually from the image
}

.landing-info {
  flex: 1;
  padding-right: 1em;
  text-align: left;

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;

    p {
      margin-top: 3px;
      margin-bottom: 0;
    }

    .title {
      font-weight: bold;
      font-size: 14pt;
    }

    .description {
      font-size: 11pt;
    }
  }
}

.landing-news {
  width: 100%;
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid #ddd; // Adds a subtle separation from the main content
}

/* Responsive Design */
@media screen and (max-width: 820px) {
  .landing-info {
    padding-right: 0;
  }

  .team-photo {
    margin-top: 1em;
  }
}